import './App.css';
import React, {useEffect, useState} from "react";

import {getAPI,postAPI}  from "./Api"


function App() {
    const [token,setToken]=useState('')
    const [vehicleList,setVehicleList]=useState([])
    const [planetList,setPlanetList]=useState([])

    const [selectedPlanet,setSelectedPlanet]=useState('')
    const [selectedVehicle,setSelectedVehicle]=useState('')

    const [selectedVehicleList,setSelectedVehicleList]=useState([])
    const [selectedPlanetList,setSelectedPlanetList]=useState([])
    const [selectedTimeList,setSelectedTimeList]=useState([])

    const [result,setResult]=useState(null)
    const [isFindPage,setIsFindPage]=useState(false)

   useEffect(()=>{
       /*return ()=> {*/
           postAPI('token').then((res) => setToken(res.token))
           getAPI('vehicles').then((res) => setVehicleList(res))
           getAPI('planets').then((res) => setPlanetList(res))
       /*}*/
   },[])

    return (
      <div style={{margin:'20px 50px', border:'5px solid #000', borderRadius:"20px"}}>
      <div style={{display:'flex', minHeight:'90vh', flexDirection:'column', textAlign:'center'}}>
      <header >
          <div style={{display:'flex',justifyContent:'right',padding:"15px"}}>
              <a href="#" onClick={()=> {
                  setIsFindPage(false)
                  setSelectedPlanetList([])
                  setSelectedVehicleList([])
                  setSelectedTimeList([])
                  setSelectedPlanet('')
                  setSelectedVehicle('')
              }} style={{marginRight:"15px"}}>Home</a> | <a target="_blank" rel="noopener noreferrer" href="https://www.geektrust.com" style={{marginLeft:"15px"}}>GeekTrust Home</a>
          </div>
          <h2 >Finding Falcone!</h2>

      </header>
      <main style={{flex:1}}>
          {!isFindPage && <div>
              <img style={{width:'100%',maxWidth:'300px'}} src={process.env.PUBLIC_URL+"falcon.png"}  alt={'falcon'}/>
              <br/>

              {result && result.status==='success' && <div>
                  <h2>Falcon Founded Sucessfully in <u>{result.planet_name}</u> planet, Total Time Taken <u>{result.timetaken}</u></h2>
                  <br/>
              </div> }
              {result && result.status!=='success' && <div>
                  <h2>Sorry! Falcone Not There, Try Another Possibilities</h2>
                  <br/>

              </div> }
              <button onClick={()=>{
                  if(vehicleList.length<1 || planetList.length<1 ){
                      alert('Please Wait! Data Not Loaded')
                      return
                  }
                  setResult(null)
                  setIsFindPage(true)}}>{result?'Try Again':'START'}</button>
          </div>}

          { isFindPage && <div>
              <marquee style={{width: '40%'}}>Select Four Plants and Four Vehicle to Search</marquee>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'inherit', margin: '15px',flexWrap:'wrap'}}>
                  <div>
                      <label>Planet</label>
                      <br/>
                      <select value={selectedPlanet} onChange={(event) => {
                          setSelectedPlanet(event.target.value);
                          setSelectedVehicle('');
                      }}>
                          <option value={''}>Select Planet</option>
                          {planetList.length > 0 && planetList.map((x, i) => {
                              return (
                                  <option hidden={selectedPlanetList.includes(x.name)} key={'planet' + i}
                                          value={x.name + '-' + x.distance}>{x.name}</option>
                              )
                          })}
                      </select>
                  </div>
                  <div>
                      <label>Vehicle</label>
                      <br/>
                      <select value={selectedVehicle} onChange={(event) => setSelectedVehicle(event.target.value)}>
                          <option value={''}>Select Vehicle</option>
                          {vehicleList.length > 0 && selectedPlanet && vehicleList.map((x, i) => {

                              let selected_planet_distance = parseInt(selectedPlanet.split('-')[1])
                              return (
                                  <option
                                      hidden={((selectedPlanet && (selected_planet_distance > x.max_distance))) || (selectedVehicleList.filter((y) => y === x.name)).length >= x.total_no}
                                      key={'vehicle' + i}
                                      value={x.name + '-' + (selected_planet_distance / parseInt(x.speed))}>{x.name}</option>
                              )
                          })}
                      </select>
                  </div>
                  <button onClick={() => {

                      if (!selectedVehicle) {
                          alert('Please Select Vehicle')
                          return
                      }
                      if (!selectedPlanet) {
                          alert('Please Select Planet')
                          return
                      }
                      if (selectedPlanetList.length === 4) {
                          alert('Already Slots Full, Try Next')
                          return
                      }
                      setSelectedPlanetList([...selectedPlanetList, selectedPlanet.split('-')[0]])
                      setSelectedVehicleList([...selectedVehicleList, selectedVehicle.split('-')[0]])
                      setSelectedTimeList([...selectedTimeList, parseInt(selectedVehicle.split('-')[1])])
                      setSelectedPlanet('')
                      setSelectedVehicle('')

                  }
                  }>Add
                  </button>
              </div>
              <table style={{borderRadius: "5px"}} border={1} align={"center"} cellPadding={"10px"} width={"80%"}>
                  <thead>
                  <tr>
                      <th>S.NO</th>
                      <th>Planet Name</th>
                      <th>Vehicle</th>
                      <th>Time Taken</th>
                  </tr>
                  </thead>
                  <tbody>
                  {[1, 2, 3, 4].map((x) => {
                      return (<tr key={'tindex' + x}>
                          <td>{x}</td>
                          {selectedPlanetList[x - 1] && <td>{selectedPlanetList[x - 1]}</td>}
                          {selectedVehicleList[x - 1] && <td>{selectedVehicleList[x - 1]}</td>}
                          {selectedTimeList[x - 1] && <td>{selectedTimeList[x - 1]}</td>}
                      </tr>)
                  })}
                  <tr>
                      <td colSpan={3}>
                          <button onClick={() => {
                              if (selectedPlanetList.length < 4) {
                                  alert('Please Fill the Slots and Press me')
                                  return
                              }
                              postAPI('find', JSON.stringify({
                                  token: token,
                                  planet_names: selectedPlanetList,
                                  vehicle_names: selectedVehicleList
                              })).then((res) => {
                                  console.log(res)
                                  res['timetaken']=[...selectedTimeList].reduce((a, b) => a + b, 0)
                                  setResult(res);
                                  setIsFindPage(false)
                                  setSelectedPlanetList([])
                                  setSelectedVehicleList([])
                                  setSelectedTimeList([])
                              })

                          }
                          }>Find Falcon
                          </button>
                          <button onClick={() => {

                              setSelectedPlanetList([])
                              setSelectedVehicleList([])
                              setSelectedTimeList([])
                          }
                          }>Reset
                          </button>
                      </td>

                      <td>{[...selectedTimeList].reduce((a, b) => a + b, 0)}</td>
                  </tr>
                  </tbody>
              </table>
          </div>}
      </main>
      <footer ><h4 >Coding Problem - <a style={{color:'black'}} target="_blank" rel="noopener noreferrer" href="https://www.geektrust.in/finding-falcone">www.geektrust.in/finding-falcone</a></h4></footer>

      </div>
      </div>

    );
}

export default App;
