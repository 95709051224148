import React from "react";

const base_url='https://findfalcone.herokuapp.com/';

export async function getAPI(url) {
    return await fetch(base_url + url).then((res) => res.json()).catch((err) => error(err))
}

export async function postAPI(url, body = '') {
    let header={Accept:"application/json"}
    if(url=='find'){
        header['Content-Type']="application/json"
    }
    return await fetch(base_url + url, {
        method: "POST",
        headers: header,
        body: body
    }).then((res) => res.json()).catch((err) => error(err))
}

function error(err){
    alert('something went wrong please check the console and try again')
    console.log(err)
}

